<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar
            flat
        >
          <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
          >
            Heute
          </v-btn>
          <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
              bottom
              right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Tag</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Woche</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Monat</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 Tage</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
            locale="de-de"
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
        ></v-calendar>
        <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
        >
          <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
          >
            <v-toolbar
                :color="selectedEvent.color"
                dark
            >
              <v-btn icon v-if="selectedEvent.type === 'event'" @click="openEvent(selectedEvent.id)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon v-else-if="selectedEvent.type === 'wedding'" @click="goTo('wedding', selectedEvent.id)">
                <v-icon>mdi-ring</v-icon>
              </v-btn>
              <v-btn icon v-else @click="goTo('task', selectedEvent.id)">
                <v-icon>mdi-format-list-checks</v-icon>
              </v-btn>
              <v-toolbar-title>{{ selectedEvent.name }} <br>
                <h5 v-if="selectedEvent.start === selectedEvent.end">{{ getStringDate(selectedEvent.start) }}</h5>
                <h5 v-else>
                  {{ getStringDate(selectedEvent.start) }}
                  <span v-if="selectedEvent.timeStart">
                    {{selectedEvent.timeStart}}
                  </span>  -
                  <span v-if="getStringDate(selectedEvent.end) !== getStringDate(selectedEvent.start)">
                    {{ getStringDate(selectedEvent.end) }}
                  </span>
                  <span v-if="selectedEvent.timeEnd">
                    {{selectedEvent.timeEnd}}
                  </span>
                </h5>
              </v-toolbar-title>
              <br><br>
              <v-spacer></v-spacer>
              <v-btn v-if="selectedEvent.type === 'event'" icon
                     @click="selectedOpen = false;$emit('deleteEvent', {eventId: selectedEvent.id})">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="secondary"
                  @click="selectedOpen = false"
              >

                Schließen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
import {date2String} from "@/helper/helper";

export default {
  name: "Calendar",
  data() {
    return {
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Monat',
        week: 'Woche',
        day: 'Tag',
        '4day': '4 Tage',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      eventsC: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    }
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    events: {
      type: Array,
      default() {
        return []
      }
    },
  },
  mounted() {
    this.eventsC = this.events
  },
  methods: {
    openEvent(id){
      this.$router.push('/calendar/event/'+id)
    },
    viewDay({date}) {
      this.focus = date
      this.type = 'day'
    },
    goTo(entity, id) {
      switch (entity) {
        case 'wedding':
          this.$router.push('/weddings/wedding/' + id)
          break
        case 'task':
          this.$router.push('planner/tasks/task/' + id)
          break
        case 'event':
          this.$router.push('/events/event/' + id)
          break
      }
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    getStringDate(date) {
      return date2String(date)
    },
    updateRange() {
      /*
      const events = []


      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0
        const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        })

      }

      this.events = events
        */

    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  }
}
</script>
